import { gsap } from 'gsap'
import * as rive from "@rive-app/canvas";
import { ScrollTrigger } from "gsap/ScrollTrigger";

$(document).ready(function() {

    $('.toggle-button').click(function(event) {
        $('.menu_text_list').toggleClass('active')
        console.log("click");
    });

});

gsap.registerPlugin(ScrollTrigger);

gsap.to(".clouds_cloud1", {
    scrollTrigger: ".clouds_cloud1",
    x: 1500,
    duration: 700,
});

gsap.to(".clouds_cloud2", {
    scrollTrigger: ".clouds_cloud2",
    x: 1500,
    duration: 500,
});

gsap.to(".clouds_cloud3", {
    scrollTrigger: ".clouds_cloud3",
    x: 1500,
    duration: 1000,
});


const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}



const head = new rive.Rive({
    src: 'animations.riv',
    artboard: 'header_desktop',
    canvas: document.getElementById("head"),
    autoplay: true,
    stateMachines: "head"
});

const head2 = new rive.Rive({
    src: 'animations.riv',
    artboard: 'header_mobile',
    canvas: document.getElementById("head2"),
    autoplay: true,
    stateMachines: "head2"
});



const r = new rive.Rive({
    src: 'animations.riv',
    // OR the path to a discoverable and public Rive asset
    // src: '/public/example.riv',
    canvas: document.getElementById("button"),
    artboard: 'buttonSmile',
    autoplay: true,
    stateMachines: "Hover",
    // onLoad: () => {
    //   r.resizeDrawingSurfaceToCanvas();
    // },
});

const r2 = new rive.Rive({
    src: 'animations.riv',
    // OR the path to a discoverable and public Rive asset
    // src: '/public/example.riv',
    canvas: document.getElementById("button2"),
    artboard: 'buttonSmile',
    autoplay: true,
    stateMachines: "Hover",
    // onLoad: () => {
    //   r.resizeDrawingSurfaceToCanvas();
    // },
});

const r3 = new rive.Rive({
    src: 'animations.riv',
    // OR the path to a discoverable and public Rive asset
    // src: '/public/example.riv',
    canvas: document.getElementById("button3"),
    artboard: 'buttonSmile',
    autoplay: true,
    stateMachines: "Hover",
    // onLoad: () => {
    //   r.resizeDrawingSurfaceToCanvas();
    // },
});

const photo = new rive.Rive({
    src: 'animations.riv',
    canvas: document.getElementById("photo"),
    artboard: 'photo',
    autoplay: true,
    stateMachines: "photo"
});

const hand1 = new rive.Rive({
    src: 'animations.riv',
    canvas: document.getElementById("hand1"),
    artboard: 'hand1',
    autoplay: true,
    stateMachines: "hand1"
});




console.log(photo)




// /**
//  * Loaders
//  */

// const loadingManager = new THREE.LoadingManager(
//     // Loaded
//     () =>
//     {
//         // Wait a little
//         window.setTimeout(() =>
//         {
//             // Animate overlay
//             gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

//             // Update loadingBarElement
//             loadingBarElement.classList.add('ended')
//             loadingBarElement.style.transform = ''
//         }, 500)

//         window.setTimeout(() =>
//         {
//             sceneReady = true
//         }, 2800)
//     },

//     // Progress
//     (itemUrl, itemsLoaded, itemsTotal) =>
//     {
//         // Calculate the progress and update the loadingBarElement
//         const progressRatio = itemsLoaded / itemsTotal
//         loadingBarElement.style.transform = `scaleX(${progressRatio})`
//     }
// )
// const gltfLoader = new GLTFLoader(loadingManager)
// const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)

// /**
//  * Base
//  */
// // Debug
// const debugObject = {}

// // Canvas
// const canvas = document.querySelector('canvas.webgl')

// // Scene
// const scene = new THREE.Scene()

// /**
//  * Overlay
//  */


// /**
//  * Update all materials
//  */
// const updateAllMaterials = () =>
// {
//     scene.traverse((child) =>
//     {
//         if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
//         {
//             // child.material.envMap = environmentMap
//             child.material.envMapIntensity = debugObject.envMapIntensity
//             child.material.needsUpdate = true
//             child.castShadow = true
//             child.receiveShadow = true
//         }
//     })
// }

// /**
//  * Models
//  */
// // gltfLoader.load(
// //     '/models/DamagedHelmet/glTF/DamagedHelmet.gltf',
// //     (gltf) =>
// //     {
// //         gltf.scene.scale.set(2.5, 2.5, 2.5)
// //         gltf.scene.rotation.y = Math.PI * 0.5
// //         scene.add(gltf.scene)

// //         updateAllMaterials()
// //     }
// // )

// const cube = new THREE.Mesh( new THREE.BoxGeometry(1,1,1), new THREE.MeshBasicMaterial({color:0x00ff00}))
// scene.add(cube)

// /**
//  * Points of Interest
//  */
// // const raycaster = new THREE.Raycaster()

// // const points = [
// //     {
// //         position: new THREE.Vector3(1.55, 0.3, -0.6),
// //         element: document.querySelector('.point-0')
// //     },
// //     {
// //         position: new THREE.Vector3(0.5, 0.8, -1.6),
// //         element: document.querySelector('.point-1')
// //     },
// //     {
// //         position: new THREE.Vector3(1.6, -1.3, -0.7),
// //         element: document.querySelector('.point-2')
// //     }
// // ]

// // console.log(points)


// /**
//  * Lights
//  */


// /**
//  * Sizes
//  */
// const sizes = {
//     width: window.innerWidth,
//     height: window.innerHeight
// }

// window.addEventListener('resize', () =>
// {
//     // Update sizes
//     sizes.width = window.innerWidth
//     sizes.height = window.innerHeight

//     // Update camera
//     camera.aspect = sizes.width / sizes.height
//     camera.updateProjectionMatrix()

//     // Update renderer
//     renderer.setSize(sizes.width, sizes.height)
//     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// })

// /**
//  * Camera
//  */
// // Base camera
// const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
// camera.position.set(4, 1, - 4)
// scene.add(camera)

// // Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

// /**
//  * Renderer
//  */
// const renderer = new THREE.WebGLRenderer({
//     canvas: canvas,
//     antialias: true
// })
// // renderer.toneMapping = THREE.ReinhardToneMapping
// // renderer.toneMappingExposure = 3
// // renderer.shadowMap.enabled = true
// // renderer.shadowMap.type = THREE.PCFSoftShadowMap
// renderer.setSize(sizes.width, sizes.height)
// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// /**
//  * Animate
//  */
// const tick = () =>
// {
//     // Update controls
//     controls.update()



   

//     // Render
//     renderer.render(scene, camera)

//     // Call tick again on the next frame
//     window.requestAnimationFrame(tick)
// }

// tick()